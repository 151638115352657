<template>
  <div class="card mx-9">
    <div class="card-header">
      <h3 class="card-title">{{ $t("aboutUs") }}</h3>
    </div>
    <div class="card-body">
      <div class="text-center mb-15 mt-5"><img src="media/logos/logo-about.svg" class="img-fluid" style=""/></div>
      <div class="fs-5 fw-bold text-gray-600" v-if="$i18n.locale=='hk'">
        RankOpen 前身始於2009年，已有13年舉辦比賽的歷史，至今已舉辦了超過50個賽事，一萬多場對賽。
        <br><br>
        我們在2015年創立了RankOpen網上比賽平台，將比賽全面電子化，參加和舉辦比賽更加便利。
        <br><br>
        在2016年得到科學園的認可，成為科學園 "網動科技創業培育計劃(Incu-App)" 其中一員。
        <br><br>
        RankOpen致力給運動愛好者一個優質的比賽機會，我們有數個優勢：
        <br><br>
        <h5>便宜的報名費用</h5>
        我們以電子化的優勢，以不到外間十分一的報名費用就能參加多場比賽，更設有小組循環比賽，不用擔心繳付高昂的報名費用在一場賽事後就被淘汰。
        <br><br>
        <h5>自由的約賽方式</h5>
        我們的平台會分配好所有比賽對手，大家可以自行決定對賽時間和地點，不用擔心遇上天氣改期，請假和時間不配對等等的問題。
        <br><br>
        <h5>便利的比賽系統</h5>
        我們的比賽系統由報名，約賽到上報分數全面電子化，所有記錄即時在網上更新，所有比賽資料和對手資訊十分齊全一目了然。
        <br><br>
        <h5>匹配的聯賽設計</h5>
        我們設有多組別的分級制，排名制度，升降班制度，每個分組都有獎勵，確保大家可以對上水平相約的對手，充滿挑戰性同時可以認識更多水平相約的球友。
        <br><br>
        我們的願景是將此理念發展到世界各地和不同的運動項目，讓運動更普及。
        <br><br>
        <div class="text-end">05.2022</div>
      </div>
      <div class="fs-5 fw-bold text-gray-600" v-if="$i18n.locale=='en'">
        The predecessor of RankOpen started in 2009 and has held competitions for 13 years. So far, more than 50 competitions and more than 10,000 matches have been held.
        <br><br>
        We created the RankOpen online competition platform in 2015 to fully digitize competitions, making it easier to participate and hold competitions.
        <br><br>
        In 2016, it was recognized by the Hong Kong Science Park and became a member of the Hong Kong Science Park's "Incu-App" programme.
        <br><br>
        RankOpen is committed to giving sports fans a high-quality competition opportunity, we have several advantages:
        <br><br>
        <h5>Affordable registration fee</h5>
        With the advantages of electronic technology, players can participate in competitions with less than 10% of the regular registration fee. We also have group round-robin competitions, so there is no need to worry about paying high registration fees and being eliminated after a one match.
        <br><br>
        <h5>The free way of match schedule</h5>
        Our platform will allocate all the opponents, and everyone can decide the time and place of the match by themselves, without worrying about the weather rescheduling, applying for annual leaves, and time mismatching and so on.
        <br><br>
        <h5>Convenient competition system</h5>
        Our competition system is fully electronic from registration, match schedule to score report, all records are updated online in real time, and all competition information and opponent information are complete and clear at a glance.
        <br><br>
        <h5>Matching level League Design</h5>
        We have a multi-group grading system, ranking system, and promotion system. Each group has rewards, we ensure that everyone can face opponents of the same level, which is full of challenges and can meet more players of the same level.
        <br><br>
        Our vision is to develop this concept to different sports around the world and make sports more accessible.
        <br><br>
        <div class="text-end">05.2022</div>
      </div>
    </div>
  </div>
</template>

<style></style>

<script>
export default {
  name: "contactPage",
};
</script>
